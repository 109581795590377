<template>
  <div :class="[{'support-wrapper' : !SM} , {'support-wrapper-sm' : SM} ]"
       style="pointer-events: none"
  >


    <template v-if="!SM && !displayMenu" >
      <v-sheet
          v-if="$store.state.support.notificationsArray.length > 0"
          @click="$store.state.support.notificationsArray=[]"
          class="pointer d-flex mb-2 ml-auto justify-center align-center"
          :color="wsBACKGROUND"
          width="32" height="32"
          style="border-radius: 50%;pointer-events: auto; "
          v-ripple
      >
        <v-icon :color="wsACCENT">mdi-close</v-icon>
      </v-sheet>
      <template v-for="(item,i) in $store.state.support.notificationsArray">
        <v-sheet v-if="i < 3" :key="i" class="overflow-hidden mb-2" style="pointer-events: auto; border-radius : 50px">
          <v-sheet
              :color="wsBACKGROUND + getPreviewOpacity(i)"
              class="pa-2 px-4"
          >
            <h5 :style="`color : ${wsDARKER + getPreviewOpacity(i)}`" class="font-weight-regular  "  >{{ getPreviewText(item.text) }}</h5>
          </v-sheet>
        </v-sheet>

      </template>
    </template>


    <div
        style="pointer-events: auto"
        v-if="!SM || (SM && !displayMenu)"
        @click="displayMenu = !displayMenu"
         class="d-flex align-center justify-center pointer ml-auto"
         :class="[{'support-button' : !SM} , {'support-button-sm' : SM} ]"
         :style="`background : ${wsATTENTION}`"
         v-ripple
    >
      <v-badge
          :value="$store.state.support.chatMessagesArray.length"
          :color="wsDARKLIGHT"
          dot
          bordered
          left

      >
      <v-icon large color="#ffffff">{{ displayMenu ? "mdi-chevron-down" : "mdi-chat-question" }}</v-icon>
      </v-badge>
    </div>

    <v-slide-x-reverse-transition>
      <support-menu-dialog
          v-if="displayMenu"
          @close="displayMenu = false"
      />
    </v-slide-x-reverse-transition>


  </div>

</template>

<script>
import SupportMenuDialog from "@/components/techSupport/SupportMenuDialog";
import {mapActions} from "vuex";
export default {
  name: "SupportButton",
  components : {
    SupportMenuDialog
  },
  data() {
    return {
      displayMenu : false
    }
  },
  methods : {
    ...mapActions('support', [
      'GET_BUSINESS_STATS',
    ]),
    getPreviewOpacity(index) {
      if ( index === 0 ) {
        return ''
      }
      if (index === 1) {
        return '99'
      }
      if (index === 2) {
        return '66'
      }
    },
    getPreviewText(text) {
      if ( !text ) {
        return ''
      }

      if (text.length > 24) {
        return text.slice(0,24) + '...'
      }

      return text
    },
    initPage() {
      this.GET_BUSINESS_STATS()
    }
  },
  computed : {
    triggerSupportButton() {
      return this.$store.state.support.triggerSupportButton
    }
  },
  watch : {
    triggerSupportButton() {
      this.displayMenu = true
    },
    displayMenu(value) {
      this.$emit('expanded' , value)
    },
    SM() {
      this.displayMenu = false
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>
.support-wrapper {
  position : fixed;
  bottom: 24px;
  right : 24px;
  z-index : 1
}
.support-wrapper-sm {
  position : fixed;
  bottom: 10px;
  right : 68px;
  z-index : 1
}
.support-button {
  border-radius: 50%;
  width: 56px;
  height: 56px;
}
.support-button-sm {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}
</style>