<template>
  <div v-if="!loading">


    <support-navigation
        :items="items"
        class="mb-4"
        :icon-color="wsACCENT"
    />
  </div>
  <div v-else class="fill-height d-flex align-center justify-center">
    <div style="width: 100px">
      <v-progress-linear rounded indeterminate :color="wsACCENT" />
    </div>
  </div>

</template>

<script>
import supportNavigation from "@/components/techSupport/UI/supportNavigation";
import {mapActions} from "vuex";

export default {
  name: "supportFaq",
  components : {
    supportNavigation
  },
  props : {
    returnTrigger : {
      type : Number
    },
    scrollPosition : {
      type : Number
    }
  },
  data() {
    return {
      items : [],
      selectedItem : null,
      loading : false
    }
  },
  methods : {
    ...mapActions('faq', [
      'GET_FAQ_ITEMS',
    ]),

    async initPage() {
      this.loading = true
      let result = await this.GET_FAQ_ITEMS(this.is_westudy ? 'westudy' : 'fine')
      this.loading = false
      if ( !result || result === true ) {
        return
      }
      this.items = result
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>