<template>
  <div v-if="!loading" class="pa-4">

    <support-navigation
        v-if="homeItems.length > 0"
        :items="homeItems"
        class="mb-4"
        bold
        :icon-color="wsACCENT"
    />

<!--    <support-navigation-->
<!--        v-if="faqItems.length > 0"-->
<!--        @input="$emit('navigation-change' , {navigation : 'faq', name : $t('FAQ')})"-->
<!--        title="support.faq"-->
<!--        :items="faqItems"-->
<!--        display-more-->
<!--        :display-more-text="$t('support.faq_view_all')"-->
<!--    >-->

<!--      <template #expand="{item}">-->
<!--        <div class="pa-4" v-html="item.expand_content"></div>-->
<!--      </template>-->

<!--    </support-navigation>-->

    <support-navigation
        v-if="newsItems.length > 0"
        @select-item="$emit('navigation-change' , {navigation : 'news', name : $t('News') , value : $event.value})"
        title="News"
        :items="newsItems"
        class="mt-4"
        display-more
        :display-more-text="$t('support.news_view_all')"
    />

    <support-navigation
        @select-item="$emit('navigation-change' , {navigation : 'chat', name : $t('Help') , value : $event.value})"
        class="mt-4"
        title="support.services.title"
        :items="servicesItems"
    />

<!--    <support-navigation-->
<!--        class="mt-4"-->
<!--        title="support.youtube"-->
<!--        :items="youtubeItems"-->
<!--    />-->

  </div>
  <div v-else class="fill-height d-flex align-center justify-center">
    <div style="width: 100px">
      <v-progress-linear rounded indeterminate :color="wsACCENT" />
    </div>
  </div>
</template>

<script>
import supportNavigation from "@/components/techSupport/UI/supportNavigation";
import {mapActions} from "vuex";
export default {
  name: "supportHome",
  components : {
    supportNavigation
  },
  data() {
    return {
      loading : false,
      requestsCount : 0,
      faqItems : [],
      newsItems : [],
      youtubeItems : [],

      inWorkCount : 0,
      updatesCount : 0,
    }
  },
  computed : {
    servicesItems() {
      return [
        { text : this.$t('support.services.video_consult.title') ,
          icon : 'mdi-monitor-account',
          bold : true ,
          value : 'video_consult'
        },
        { text : this.$t('support.services.website.title') ,
          bold : true,
          icon : 'mdi-web-box' ,
          value : 'website'
        },
        { text : this.$t('support.services.content.title'),
          bold : true,
          icon : 'mdi-newspaper' ,
          value : 'content' },
      ]
    },
    homeItems() {

      let items = []
      if ( this.requestsCount > 0 ) {
        items.unshift( { text : this.$t('support.my_requests'),
          action : () => { return this.$emit('open-chat') },
          icon : 'mdi-forum',
          icon_color : this.wsACCENT,
          bold : true,
          counter : this.$store.state.support.chatMessagesArray.length
        })
      }

      if ( this.updatesCount > 0 ) {
        items.push({ text : this.$t('support.updates.title'),
          icon : 'mdi-bell-ring',
          icon_color : this.wsACCENT,
          bold : true,
          action : () => { this.navigation = 'updates' ; this.$emit('navigation-change' , {navigation : 'updates', name : this.$t('support.updates.title')}) }
        })
      }

      if ( this.inWorkCount > 0 ) {
        items.push(  { text : this.$t('support.updates.in_work.title'),
          icon : 'mdi-flare',
          icon_color : this.wsACCENT,
          bold : true,
          action : () => { this.navigation = 'in_work' ; this.$emit('navigation-change' , {navigation : 'in_work', name : this.$t('support.updates.in_work.title')}) }
        })
      }

      return items
    }
  },
  methods : {

    ...mapActions('support', [
      'GET_SUPPORT_HOME',
    ]),

    async initPage() {
      this.loading = true
      let result = await this.GET_SUPPORT_HOME()
      this.loading = false
      if ( !result ) {
        return this.ERROR()
      }
      this.requestsCount = result.requests_count
      this.faqItems = result.faq_items
      this.newsItems = result.news_items

      this.updatesCount = result.updates_count
      this.inWorkCount = result.in_work_count

    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>