<template>
<v-sheet color="transparent" class="fill-height" >

  <div class="fill-height d-flex align-center justify-center" v-if="loading">
    <div style="width: 100px">
      <v-progress-linear rounded indeterminate :color="wsACCENT" />
    </div>
  </div>
  <!-- Create Chat -->
  <chat-support-create
      v-else-if="navigation==='new'"
      :chat-types="newChatItems"
      @new-chat="startNewChat"
  />
  <!-- View Chats -->
  <chat-support-list
      v-else-if="navigation==='list'"
      @select="openChat"
      @open-new="openNewChat"
      :chat-types="newChatItems"
      :items="requests"
  />
  <!-- Chat window -->
  <chat-support-view
      v-else-if="navigation==='view'"
      :chat-id="selectedChat"
      :new-chat-type="newChatType"
      @add-request="requests.unshift($event)"
      @update-request="updateRequest($event)"
  />
</v-sheet>
</template>

<script>
import chatSupportCreate from "@/components/techSupport/pages/chatSupportCreate";
import chatSupportList from "@/components/techSupport/pages/chatSupportList";
import chatSupportView from "@/components/techSupport/pages/chatSupportView";
import {mapActions, mapState} from "vuex";

export default {
  name: "supportChat",
  props : {
    returnTrigger : {
      type : Number
    },
    displayNewTrigger : {
      type: Number,
      default: 0
    },
    navigationValue : {
      type: String,
    }
  },
  components : {
    chatSupportCreate,
    chatSupportList,
    chatSupportView,
  },
  data() {
    return {
      navigation : 'new',
      requests : [],
      selectedChat : null,
      newChatType : null,
      loading : false,
      preventList : false
    }
  },
  computed : {
    ...mapState('support' , ['chatMessagesArray']),
    newMessagesCount() {
      return this.chatMessagesArray.length
    },
    newChatItems() {
      return [
        { text : this.$t('support.request.bug.title')     , bold : true, icon : 'mdi-bug' , value : 'bug' },
        { text : this.$t('support.request.feature.title') , bold : true, icon : 'mdi-flare' , value : 'feature_request' },
        { text : this.$t('support.services.video_consult.title'), bold : true, icon : 'mdi-monitor-account' , value : 'video_consult' },
        { text : this.$t('support.services.website.title')      , bold : true, icon : 'mdi-web-box' , value : 'website' },
        { text : this.$t('support.services.content.title')      , bold : true, icon : 'mdi-newspaper' , value : 'content' },
        { text : this.$t('support.chat.contact')          , bold : true, icon : 'mdi-face-agent', value : 'new_chat' }
      ]
    }
  },
  watch : {
    returnTrigger() {
      this.handleReturn()
    },
    displayNewTrigger() {
      this.preventList = true
      setTimeout(this.openNewChat, 4)
    },
    newMessagesCount(value) {
      if ( value > 0) {
        let message = this.chatMessagesArray[0]
        this.updateRequest({issue : message.issue, hasUpdates : true, preview : message.text })
      }
    }
  },
  methods : {
    ...mapActions('support', [
      'GET_BUSINESS_REQUESTS',
    ]),

    updateRequest(data) {

      let {issue, preview , hasUpdates} =  data

      let index = this.requests.findIndex(el => el.uuid === issue )
      if ( index === -1 ) {
        return
      }

      if ( preview ) {
        this.requests[index].preview = preview.slice(0, 30)
        this.requests = this.COPY(this.requests)
      }

      if ( hasUpdates && issue !== this.selectedChat ) {
        this.requests[index].has_updates = true
        let buffer = this.COPY(this.requests[index])
        this.requests = this.requests.filter(el => el.uuid !== issue)
        this.requests.unshift(buffer)
      }

    },
    async getRequests() {
      let result = await this.GET_BUSINESS_REQUESTS()
      if ( !result ) {
        return this.ERROR()
      }
      this.requests =  result.requests
    },
    startNewChat({type,chatTitle}) {
      this.newChatType = type
      this.navigation = 'view'
      this.$emit('update-header' , {name : chatTitle , displayReturn : true } )
    },
    openNewChat() {
      this.navigation = 'new'
      this.selectedChat = null
      if ( this.requests.length > 0  ) {
        this.$emit('update-header' , {name : this.$t('Help') , displayReturn : true } )
      }
    },
    handleReturn() {

      if ( this.selectedChat ) {
        this.selectedChat = null
        this.navigation = 'list'
        this.$emit('update-header' , {} )
      } else {
        this.navigation = this.requests.length > 0 ? 'list' : 'new'
        this.$emit('update-header' , {} )
      }
    },
    openChat(chat) {
      this.selectedChat = chat.uuid
      this.navigation = 'view'
      let chatTitle = this.newChatItems.find(el => el.value === chat.type).text
      this.$emit('update-header' , { name : chatTitle , displayReturn : true } )
      let index = this.requests.findIndex(el => el.uuid === chat.uuid )
      if ( index === -1 ) {
        return
      }
      this.requests[index].has_updates = false
      this.requests = this.COPY(this.requests)
    },
    checkNavigationValue() {

      if ( !this.$store.state.support.navigationValue ) {
        return
      }
      let item = this.newChatItems.find(el => el.value === this.$store.state.support.navigationValue )
      if ( !item ) {
        return
      }
      this.$store.state.support.navigationValue = null
      this.startNewChat({type : item.value,chatTitle : item.text})
    }
  },
  async mounted() {
    this.$store.state.support.displayArchive = false
    this.loading = true
    await this.getRequests()
    this.loading = false
    if ( this.requests.length > 0 && !this.preventList ) {
      this.navigation = 'list'
    }
    this.checkNavigationValue()
  }
}
</script>

<style scoped>

</style>