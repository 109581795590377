<template>

    <v-fade-transition class="fill-height" :style="`background-color: white`" mode="out-in">

      <v-sheet :color="wsWARNING" dark v-if="BUSINESS_SUSPENDED || USER_SUSPENDED" class="pa-16 d-flex align-center justify-center">
        <h1 style="width: 700px" class="text-center">{{ BUSINESS_SUSPENDED ?  'Business' : 'User' }} account has been suspended for suspicious activity, please contact WeStudy tech support for details</h1>
      </v-sheet>

      <div v-else-if="loggedIn && !$store.state.auth.user.phone">
        <v-sheet :color="wsBACKGROUND" class="fill-height">
          <ws-dialog
              :value="true"
              @save="addPhoneNumber"
              :title="$t('EnterPhoneNumber')"
          >

            <h4 :style="`color : ${wsACCENT}`" class="mb-10 mt-5 text-center">
              {{ $t('NoPhoneDashboardWarning') }}
            </h4>

            <ws-phone-input
                :label="$t('Phone')"
                v-model="phoneNumber"
                :error-messages="phoneError"
                :error="!!phoneError"
                @input="phoneError = false"
                :hide-details="false"
            />
          </ws-dialog>
        </v-sheet>
      </div>
      <div v-else-if="loggedIn && !loading" class="fill-height">

        <portal to="loading_line">
          <v-sheet v-if="LOADING" color="transparent" style="position: fixed; top :0 ; left: 0 ; right: 0; height: 10px; z-index: 99999999999999">
            <v-progress-linear  indeterminate :color="wsACCENT" height="5" />
          </v-sheet>
        </portal>
        <maintenance-mode-window v-if="$store.state.MAINTENANCE_ON" />

        <!-- Audio Player-->
        <v-slide-x-transition>
          <ws-audio-player v-if="$store.state.audioPlayer.display" />
        </v-slide-x-transition>
        <!-- Video Player-->
        <v-slide-x-transition>
          <ws-video-player
              v-if="$store.state.videoPlayer.display"
              v-model="$store.state.videoPlayer.display"
              :component="$store.state.videoPlayer.component"
          />
        </v-slide-x-transition>

        <v-sheet v-if="!SM" id="business_dash_content"  :color="wsBACKGROUND" class=" fill-height  " >

          <v-row no-gutters  style="flex-wrap: nowrap; height: 100% !important" class="fill-height"  >

            <v-col :style="sideBarStyle" class="flex-grow-0 flex-shrink-0 sideTransition fill-height">
              <sideBarBusiness />
            </v-col>

            <v-col  style="min-width: 100px; max-width: 100%;" class="flex-grow-1 flex-shrink-0">
              <support-button v-if="$store.state.business.selectedBusiness.alias && $store.state.dashboard.showBusinessToOwner || $store.state.dashboard.showBusinessToManager" />
              <router-view></router-view>
            </v-col>

          </v-row>

        </v-sheet>

        <sideBarBusinessMobile  v-if=" SM && $store.state.dashboard.sideMenuMobile" />
        <div v-if="SM" class="fill-height" >
          <support-button
              v-if="$store.state.business.selectedBusiness.alias && $store.state.dashboard.showBusinessToOwner || $store.state.dashboard.showBusinessToManager"
              @expanded="supportIsExpanded = $event"
          />

          <v-btn
              v-if="!supportIsExpanded"
              style="position:fixed; bottom: 10px; right: 10px; z-index: 2; min-width: 50px; border-radius: 50%"
              height="50px"
              class="px-0"
              @click="$store.state.dashboard.sideMenuMobile = true "
              dark
              :color="wsATTENTION"
              elevation="5"
          >
            <v-icon > mdi-menu </v-icon>
          </v-btn>

          <v-sheet  class="fill-height">
            <router-view ></router-view>
          </v-sheet>
          <footerDashMobile v-if="false" />
        </div>


<!--    :: DIALOGS ::     -->
        <ws-dialog v-if="$store.state.dashboard.showLogoutConfirm"
                   v-model="$store.state.dashboard.showLogoutConfirm"
                   :title="$t('Logout')"
                   :save-text="$t('Yes')"
                   @delete="logout"
                   display-confirm-delete
                   close-delete
                   delete-text="Exit"
                   portal
        >
          <template #confirm-delete>
            <h3 class="my-12 text-center ">{{ $t('AreYouSureYouWantToQuit') }}</h3>
          </template>
        </ws-dialog>
        <!-- Update Description Dialog-->
        <update-description-dialog
            v-if="$store.state.events.displayUpdateDescription"
            v-model="$store.state.events.displayUpdateDescription"
        />

        <ws-dialog v-if="false"
                   v-model="$store.state.events.displayUpdateDescription"
                   width="800"
                   cancel-action
                   persistent
                   :show-save="false"
                   @close="READ_EVENTS('update_description'); $store.state.events.displayUpdateDescription = false "
                   :cancel-text="$t('Close')"
                   @cancel="READ_EVENTS('update_description'); $store.state.events.displayUpdateDescription = false "
                   :title="$t('UpdateDescription')">


          <v-row>
                          <v-col v-if="!SM" cols="2">
                            <div class="fill-height d-flex align-center">
                              <v-img   src="@/assets/img/updateDescriptionImage.png" />
                            </div>
                          </v-col>
            <v-col :cols="!SM ? 10 : 12">
              <div class="d-flex align-center fill-height">
                <h5 :class="{'text-center' : SM}" class="font-weight-medium ">{{ $t('UpdateDescriptionText') }}</h5>
              </div>

            </v-col>
          </v-row>


          <v-sheet outlined class="wsRoundedHalf pa-6 mb-3 mt-6">

            <v-row>
<!--              <v-col v-if="!SM" cols="5">-->
<!--                <div class="fill-height d-flex align-center">-->
<!--                  <v-img   src="@/assets/img/updateDescriptionImage.png" />-->
<!--                </div>-->
<!--              </v-col>-->
              <v-col cols="12">
                <div class="fill-height d-flex align-center">
                  <div>
                    <h5 class="font-weight-light " v-html="$store.state.events.updateDescription.content"></h5>
                  </div>
                </div>

              </v-col>
            </v-row>
          </v-sheet>




        </ws-dialog>
        <course-completion-dialog v-if="$store.state.courses.displayCourseCompletionDialog" />
      </div>

      <v-sheet v-else-if="!loggedIn && !loading" :color="wsBACKGROUND" class="d-flex align-center justify-center" >
        <maintenance-mode-window v-if="$store.state.MAINTENANCE_ON" />
        <v-sheet class=" pa-6 px-12" :class=" displayHide(['sm']) ? 'wsRoundedHalf' : '' "
                 :width="displayHide(['sm']) ? 500 : '100vh'"
                 :height="displayHide(['sm']) ? '' : '100vh'">

          <h3 v-if="displayHide(['sm'])"  style="line-height: 1.2" class="mt-3  font-weight-medium text-center"> {{ $t('PleaseLoginToProceed') }}  </h3>
          <h4 v-else  style="line-height: 1.2" class="mt-3  font-weight-medium text-center"> {{ $t('PleaseLoginToProceed') }}  </h4>
          <auth-window-new
              no-auth-action
              display-login
              width="100%"
              class="mt-16"
          />
        </v-sheet>
      </v-sheet>
      <v-sheet dark v-if="loading" :color="wsBACKGROUND" class="d-flex align-center justify-center fill-height">
        <div>
          <div class="d-flex justify-center">
            <v-progress-circular class="text-center mx-auto" size="48" indeterminate />
          </div>
          <h3 class="text-center font-weight-light">{{ $t('Loading') }}</h3>
        </div>


      </v-sheet>

    </v-fade-transition>

</template>

<script>

import sideBarBusiness from "@/components/pages/businessDashboard/UI/sidebarBusiness/sideBarBusiness";
import sideBarBusinessMobile from "@/components/pages/businessDashboard/UI/sidebarBusiness/sideBarBusinessMobile";
import footerDashMobile from "@/components/pages/dashboard/UI/footerDashMobile";
import {mapState, mapActions, mapMutations} from "vuex";
import authWindowNew from "@/components/auth/authWindowNew";
import wsAudioPlayer from "@/components/UI/audioPlayer/wsAudioPlayer";
import wsVideoPlayer from "@/components/UI/videoPlayer/wsVideoPlayer";
import courseCompletionDialog from "@/components/pages/westudy/student/StudentCourse/courseCompletionDialog";
import maintenanceModeWindow from "@/store/maintenanceModeWindow";
import supportButton from "@/components/techSupport/SupportButton";
import updateDescriptionDialog from "@/components/pages/businessDashboard/UI/updateDescriptionDialog";
export default {
  name: 'PageBusinessDashboard',
  metaInfo() {
    if ( !this.IS_ROUTE_CHANGED )  {
      return {}
    }
    return {
      title : this.$t('PersonalDashboard'),
      titleTemplate: this.$store.state.business.selectedBusiness.name ? `%s | ${ this.$store.state.business.selectedBusiness.name } ` :   `%s | WeStudy`,
    }
  },
  props: ['alias'],
  components: {
    wsAudioPlayer,
    wsVideoPlayer,
    sideBarBusiness,
    authWindowNew,
    sideBarBusinessMobile,
    footerDashMobile,
    courseCompletionDialog,
    maintenanceModeWindow,
    supportButton,
    updateDescriptionDialog
  },
  data() {
    return {
      menuActive: false,
      loading : true,
      phoneNumber : null,
      phoneError : null,
      supportIsExpanded : false
    }
  },
  computed: {
    ...mapState('dashboard', ['sideMenu','pageTitle','breadcrumbs','modalDash','selectedBusiness']),
    ...mapState('business', ['selectedBusiness']),
    ...mapState('auth', ['loggedIn']),

    sideBarStyle() {
      let width = this.sideMenu ? 280 : 75;
      return `min-width: ${width}px; max-width: ${width}px; height: 100% !important; transition : all 0.3s ease`
    },

    mainClass() {
      if ( this.displayShow(['sm'])) { return 'mainSmall' }
      return this.sideMenu ? 'mainWithMenu' : 'main';
    }

  },
  methods : {
    ...mapActions('business', ['GET_BUSINESS_DASHBOARD' , 'ADD_PHONE_NUMBER' , 'GET_BUSINESS']),
    ...mapActions('auth', ['ADD_PHONE_NUMBER' , 'CHECK_EMAIL']),
    ...mapMutations('auth',['LOGOUT']),
    ...mapActions('documentflow', [
      'GET_SIGNATURE_REQUESTS_COUNT',
    ]),

    async addPhoneNumber() {
      if ( !this.phoneNumber) {
        this.phoneError = this.$t('EnterPhone')
        return this.notify(this.$t('EnterPhone') , 'warning')
      }

      if ( !await this.CHECK_EMAIL({ type : 'phone', phone : this.phoneNumber }) ) {
        this.phoneError = this.$t("UserAlreadyExists");
        return this.notify(this.$t('UserAlreadyExists') , 'warning')
      }

      let result = await this.ADD_PHONE_NUMBER({ phone : this.phoneNumber })
      if ( !result ) {
        return this.notify(this.$t('NetworkError'))
      }
      this.$store.state.auth.user.phone = result.phone
    },
    logout() {
      this.$store.state.dashboard.showLogoutConfirm = false
      this.LOGOUT(this.$router)
      this.$router.push(this.businessPublicLink(''))
    },
    async initBusiness() {
      this.loading = true
      if ( !this.loggedIn ) {
        let business = await this.GET_BUSINESS(this.$store.state.domain ? location.host : this.alias)
        if ( business.result ) {
          this.$store.state.business.selectedBusiness = business.data
        } else {
          this.ERROR()
        }

      } else {
        await this.GET_BUSINESS_DASHBOARD(this.$store.state.domain ? location.host : this.alias)
      }

      this.loading = false
    },
    handleDocumentflowStats() {
      if ( !this.MODULE_PERMISSIONS(['MODULE_DOCUMENTFLOW'] , true)) {
        return
      }
      this.GET_SIGNATURE_REQUESTS_COUNT()
    }
  },
  watch: {
    async loggedIn(value) {
      if (value) {
        await this.initBusiness()
        this.handleDocumentflowStats()
      }
    },
    windowSize(val,old) {
      if ( val === 'sm' ) {
        this.$store.state.dashboard.sideMenu = false
      }
      if ( old === 'sm' ) {
        this.$store.state.dashboard.sideMenu = true
      }
    }
  },
  beforeMount() {
    this.$store.state.avalon.editorMode = false
  },
  mounted() {
    this.initBusiness()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.wrapper {
  height:100% ;
}
.mainSmall {
  transition: margin-left 0.3s ease-out;
}
.main {
  margin-left: 60px !important;
  transition: margin-left 0.3s ease-out;
}
.transition {
  transition: margin-left 0.3s ease-out;
}
.mainWithMenu {
  margin-left: 250px !important;
}


.content {
  overflow-y: unset;
  background-color: white;
}


</style>
