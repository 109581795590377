<template>
  <portal to="dialog" :disabled="!SM">
    <v-sheet
        :width="!SM ? ( !wideMode ? 320 : 528) : '100%'"
        :color="wsLIGHTCARD"
        class="d-flex flex-column  overflow-hidden"
        :class="[{'support-menu-wrapper' : !SM} , {'support-menu-wrapper-sm' : SM} ]"
        style="transition: width 0.3s ease;pointer-events: auto"
        elevation="2"
    >

      <!-- Header-->
      <support-header
          @open-help="openHelp"
          :navigation="navigation"
          :navigation-text="selectedNavigationText"
          :link="headerLink"
          :display-return="displayReturn"
          @return="returnTrigger++"
          @close="$emit('close')"
      />

      <!-- Content -->
      <v-sheet color="transparent" @scroll="handleScroll" class="flex-grow-1 overflow-y-auto" id="support_chat_content_view"
      >

        <support-home
            v-if="navigation === 'home'"
            @open-chat="navigation = 'chat'"
            @navigation-change="handleHomeNavigationChange"
        />
        <support-chat
            v-if="navigation === 'chat'"
            @update-header="updateHeader"
            :return-trigger="returnTrigger"
            :display-new-trigger="displayNewChatTrigger"
        />
        <support-knowledge
            v-if="navigation === 'knowledge'"
            @update-header="updateHeader"
            :return-trigger="returnTrigger"
        />
        <support-news
            v-if="navigation === 'news'"
            @update-header="updateHeader"
            :return-trigger="returnTrigger"
            :scroll-position="scrollPosition"
        />
        <support-faq
            v-if="navigation === 'faq'"
            @update-header="updateHeader"
            :return-trigger="returnTrigger"
            :scroll-position="scrollPosition"
        />
        <support-updates
            v-if="navigation === 'updates'"
            @update-header="updateHeader"
            :return-trigger="returnTrigger"
            :scroll-position="scrollPosition"
        />

        <support-in-work
            v-if="navigation === 'in_work'"
            @update-header="updateHeader"
            :return-trigger="returnTrigger"
            :scroll-position="scrollPosition"
        />


      </v-sheet>

      <!-- Footer -->
      <portal-target name="support_footer">
        <support-footer
            @input="handleNavigationChange"
            v-model="navigation"
            :items="navigationItems"
        />
      </portal-target>



    </v-sheet>
  </portal>

</template>

<script>
import supportHome from "@/components/techSupport/pages/supportHome";
import supportChat from "@/components/techSupport/pages/supportChat";
import supportKnowledge from "@/components/techSupport/pages/supportKnowledge";
import supportNews from "@/components/techSupport/pages/supportNews";
import supportFaq from "@/components/techSupport/pages/supportFaq";
import supportUpdates from "@/components/techSupport/pages/supportUpdates";
import supportInWork from "@/components/techSupport/pages/supportInWork";

import supportHeader from "@/components/techSupport/UI/supportHeader";
import supportFooter from "@/components/techSupport/UI/supportFooter";

export default {
  name: "SupportMenuDialog",
  components : {
    supportHome,
    supportChat,
    supportKnowledge,
    supportNews,
    supportFooter,
    supportHeader,
    supportFaq,
    supportUpdates,
    supportInWork
  },
  data() {
    return {
      navigation : 'home',
      displayReturn : false,
      navigationText : null,
      returnTrigger : 0,
      wideMode : false,
      displayNewChatTrigger : 0,
      scrollPosition : 0,
      headerLink : null,
      navigationValue : null
    }
  },
  computed : {
    triggerSupportChat() {
      return this.$store.state.support.triggerSupportChat
    },
    navigationItems() {
      return [
        {
          text : this.$t('Dashboard') ,
          icon : 'mdi-home',
          value : 'home'
        },
        {
          text : this.$t('Help') ,
          icon : 'mdi-message-text',
          value : 'chat'
        },
        {
          text : this.$t('DocumentationPage') ,
          icon : 'mdi-bank',
          value : 'knowledge'
        },
        {
          text : this.$t('News') ,
          icon : 'mdi-newspaper-variant-outline',
          value : 'news'
        }
      ]
    },
    selectedNavigationText() {
      if ( this.navigationText) {
        return this.navigationText
      }

      let item = this.navigationItems.find(el => el.value === this.navigation)

      return item ? item.text : null
    }
  },
  watch : {
    triggerSupportChat() {
      this.openHelp()
    },
    SM(value) {
      this.$store.state.MOBILE_FULLSCREEN = value
    }
  },
  methods : {
    handleScroll() {
      let view = document.getElementById('support_chat_content_view')
      if ( !view ) {
        return
      }
      this.scrollPosition = view.scrollTop
    },
    handleHomeNavigationChange({navigation,value,name}) {
      this.handleNavigationChange()
      this.updateHeader({name})
      this.navigation = navigation
      if ( value ) {
        this.$store.state.support.navigationValue = value
      }
    },
    handleNavigationChange() {
      this.displayReturn = false;
      this.navigationText = null;
      this.wideMode = false
    },
    updateHeader({ name , displayReturn , wide , link = null }) {

      this.navigationText = name || null
      this.displayReturn = displayReturn || false
      this.wideMode = wide || false
      this.headerLink = link

    },
    openHelp() {
      this.navigation = 'chat'
      setTimeout(() => {
        this.displayNewChatTrigger++
      })

    }
  },
  mounted() {
    if ( this.SM ) {
      this.$store.state.MOBILE_FULLSCREEN = true
    }

    if ( this.$store.state.support.chatMessagesArray.length ) {
      this.handleHomeNavigationChange({navigation : 'chat' , name : this.$t('Help')})
    }

  },
  beforeDestroy() {
    this.$store.state.MOBILE_FULLSCREEN = false
  }
}
</script>

<style scoped>
.support-menu-wrapper {
  border-radius: 16px;
  position : fixed;
  top: 24px;
  bottom: 90px;
  right : 24px;
  z-index : 1
}
.support-menu-wrapper-sm {
  border-radius: 0px;
  position : absolute;
  top: 0;
  bottom: 0;
  right : 0;
  left: 0;
  z-index : 2
}

</style>